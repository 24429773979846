import React from 'react';
import { Link } from 'gatsby';
import Loadable from 'react-loadable';

import WatchItem from './WatchItem';
import { getFormatedEpisodesCategoryTitle } from '../../utils/watch';

const subCategorySlugToURLMap = {
  'season-1': 'season1',
  'season-2': 'season2',
  'season-3': 'season3',
  'the-7-churches-of-revelation': '7-churches',
  'the-great-controversy': 'the-great-controversy',
};

const categorySlugToURLMap = {
  '360-tours': '360',
};

const loader = () => <div>Loading...</div>;

const LoadableCarousel = Loadable({
  loader: () => import('../Carousel/CarouselSlider'),
  loading: loader,
});

const getViewAllLink = (categoryName, subCategoryName = '') => {
  if (['bible', 'timeFrame'].includes(categoryName)) {
    return `/sub_periods/`.concat(subCategoryName);
  } else if (['people', 'places'].includes(categoryName)) {
    return `/${categoryName}`;
  } else {
    return !subCategoryName
      ? '/episode_category/'.concat(categorySlugToURLMap[categoryName] || categoryName)
      : '/episode_category/'.concat(subCategorySlugToURLMap[subCategoryName] || subCategoryName);
  }
};

export default function SubCategory({ data, type, noSiblingCategory, subCategoryName, categoryName }) {
  const viewAllLink = getViewAllLink(categoryName, subCategoryName);

  return (
    <div className="cat_watch-body-list">
      <div className={noSiblingCategory ? 'cat_watch-head cat-title' : 'cat_watch-title d-flex'}>
        {!!subCategoryName && (
          <span className="watch_season-duration">
            {data?.title === 'Place' ? 'Places' : subCategoryName.replace(/-/g, ' ')}
          </span>
        )}
        {data?.seasonTitle && (
          <>
            <span className="separator">|</span>
            <span className="watch_season-title">{data.seasonTitle.replace(/-/g, ' ')}</span>
          </>
        )}
      </div>
      {!!noSiblingCategory && (
        <div className="cat_watch-title d-flex">
          <div className="cat_watch-head cat-title">{getFormatedEpisodesCategoryTitle(categoryName)}</div>
        </div>
      )}
      <div className="cat_watch-item">
        <LoadableCarousel
          key={categoryName + subCategoryName}
          className={`watch_item-row d-flex owl-carousel ${type === 'landscape' ? 'la-landscape' : 'la-potrait'}`}
          responsiveClass
          margin={10}
          responsive={
            type === 'landscape'
              ? {
                  0: {
                    items: 1,
                    margin: 15,
                    nav: false,
                    stagePadding: 40,
                  },
                  576: {
                    items: 2,
                    margin: 10,
                    nav: false,
                    stagePadding: 40,
                  },
                  768: {
                    items: 4,
                    nav: false,
                    loop: false,
                  },
                  1200: {
                    items: 4,
                    nav: true,
                    loop: false,
                  },
                }
              : {
                  0: {
                    items: 2,
                    margin: 15,
                    nav: false,
                    stagePadding: 0,
                  },
                  576: {
                    items: 3,
                    margin: 10,
                    nav: false,
                    stagePadding: 40,
                  },
                  768: {
                    items: 4,
                    nav: false,
                  },
                  991: {
                    items: 6,
                    nav: true,
                    loop: false,
                  },
                  1200: {
                    items: 6,
                    nav: true,
                    loop: false,
                  },
                }
          }
        >
          {!!data?.length &&
            data
              .slice(0, 12)
              .map(
                (episode, index) =>
                  !!episode && <WatchItem type={type} key={index} data={episode} categoryName={categoryName} />
              )}
        </LoadableCarousel>
      </div>
      {viewAllLink && (
        <div className="cat_watch-link">
          <Link to={viewAllLink} className="cat-link cat-link-light">
            View All
          </Link>
        </div>
      )}
    </div>
  );
}
