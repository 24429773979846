import { Link, graphql } from 'gatsby';
import { isEmpty } from 'lodash';
import Loadable from 'react-loadable';
import React, { useState } from 'react';
import BodyClassName from 'react-body-classname';
import BackgroundImage from 'gatsby-background-image';

import 'owl.carousel/dist/assets/owl.carousel.css';
import 'owl.carousel/dist/assets/owl.theme.default.css';
import WatchCategory from '../components/Watch/WatchCategory';
import WatchFilterSlider from '../components/Carousel/WatchFilterSlider';
import DefaultLayoutComponent from '../modules/layouts/DefaultLayoutComponent';
import { normalizeWatchCategories, normalizePeople, normalizePlaces, sortNormalizedWatchData } from '../utils/watch';

const loader = () => <></>;

const LoadableBannerCarousel = Loadable({
  loader: () => import('../components/Carousel/WatchTopSlider'),
  loading: loader,
});

function WatchPage(props) {
  const {
    data: { bgWatch, featuredSeriesEpisodes, totalWatchData, people, places },
  } = props;

  const mappedData = normalizeWatchCategories(totalWatchData?.nodes || []);
  const [selectedCat, setSelectedCat] = useState('all');

  const normalizedPeople = normalizePeople(people?.edges);
  const normalizedPlaces = normalizePlaces(places?.edges);
  const normalizedPeopleAndPlaces = { ...normalizedPeople, ...normalizedPlaces };
  const totalNormalizedData = sortNormalizedWatchData({ ...mappedData, ...normalizedPeopleAndPlaces });
  const featuredEpisodes = [...featuredSeriesEpisodes.nodes];

  return (
    <BodyClassName className="body-light page-watch">
      <DefaultLayoutComponent title="Watch">
        <div className="site-main">
          <BackgroundImage
            Tag="section"
            className="page-banner bgImage bg-pos-center-top"
            fluid={bgWatch.childImageSharp.fluid}
            preserveStackingContext
          >
            <div className="overlay" />
            <div className="wrapper wrapper-lg pd-x-sm la-pn">
              <div className="carousel pd-x-sm la-pn slideshow-container">
                <LoadableBannerCarousel episodes={featuredEpisodes} />
              </div>
            </div>
          </BackgroundImage>
          <section className="categories-watch categories-filters bg-dark">
            <div className="wrapper wrapper-lg pd-x-md">
              <div className="filter-categories">
                <WatchFilterSlider
                  categories={Object.keys(totalNormalizedData)}
                  onCatSelected={setSelectedCat}
                  selectedCat={selectedCat}
                />
              </div>

              <div className="cat_watch-list">
                {!isEmpty(totalNormalizedData) &&
                  Object.keys(totalNormalizedData)
                    .filter((elm) => {
                      if (selectedCat !== 'all') {
                        return selectedCat === elm;
                      }

                      return true;
                    })
                    .map((watchCategoryName, index) => {
                      return (
                        <WatchCategory
                          key={`${index}-${watchCategoryName}`}
                          title={watchCategoryName}
                          data={totalNormalizedData[watchCategoryName]}
                          showCategory={true}
                        />
                      );
                    })}
              </div>
            </div>
          </section>
        </div>
      </DefaultLayoutComponent>
    </BodyClassName>
  );
}

export const query = graphql`
  fragment FeaturedEpisodes on PrismicEpisodes {
    data {
      language
      priority
      videoDuration: video_duration {
        text
      }
      youtubeId: youtube_id {
        text
      }
      bts
      title {
        text
      }
      thumbnailImage: thumbnail_image {
        url
      }
      categories {
        episodeCategory: episodes_category {
          categoryType: slug
        }
      }
    }
    slugs
    uid
    url
  }

  {
    featuredSeriesEpisodes: allPrismicEpisodes(
      filter: {
        data: {
          categories: { elemMatch: { episodes_category: { slug: { in: ["featured"] } } } }
          language: { eq: "English" }
        }
      }
      sort: { order: ASC, fields: last_publication_date }
    ) {
      nodes {
        ...FeaturedEpisodes
      }
    }

    bgWatch: file(relativePath: { eq: "bg-watch.jpg" }) {
      childImageSharp {
        fluid(quality: 100, maxWidth: 1280) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }

    people: allPrismicPeople(sort: { fields: data___title___text, order: ASC }) {
      edges {
        node {
          data {
            image {
              fluid(maxWidth: 600) {
                ...GatsbyPrismicImageFluid
              }
            }
            metaDescription: meta_description {
              text
            }
            priority
            summary {
              text
            }
            title {
              text
            }
            birthdate
            dateOfDeath: date_of_death
          }
          uid
        }
      }
    }
    places: allPrismicPlaces(sort: { order: ASC, fields: data___place_name___text }) {
      edges {
        node {
          id
          data {
            backgroundImage: background_image {
              url
            }
            placeName: place_name {
              text
            }
          }
          uid
        }
      }
    }

    totalWatchData: allPrismicEpisodes(
      filter: {
        data: {
          categories: {
            elemMatch: {
              episodes_category: {
                slug: {
                  in: [
                    "season-1"
                    "season-2"
                    "season-3"
                    "adverts"
                    "trailers"
                    "the-7-churches-of-revelation"
                    "the-great-controversy"
                    "360-tours"
                    "documentaries"
                    "locations-from-the-sky"
                  ]
                }
              }
            }
          }
          language: { eq: "English" }
        }
      }
      sort: { fields: data___priority, order: ASC }
    ) {
      nodes {
        data {
          categories {
            episodeCategory: episodes_category {
              categoryType: slug
            }
          }
          description {
            text
          }
          language
          priority
          videoDuration: video_duration {
            text
          }
          youtubeId: youtube_id {
            text
          }
          bts
          subPeriods: sub_period {
            subPeriod: sub_period1 {
              slug
            }
          }
          people {
            name: people1 {
              authorName: slug
            }
          }
          places {
            name: places1 {
              placeName: slug
            }
          }
          relatedArticles: related_articles {
            articles {
              slug
            }
          }
          title {
            text
          }
          thumbnailImage: thumbnail_image {
            url
          }
        }
        slugs
        uid
        url
      }
    }
  }
`;

export default WatchPage;
