import React from 'react';

import SubCategory from './SubCategory';

export default function WatchCategory({ title, data, showCategory }) {
  // People and Places categories are objects with subcategory
  // but should be treated as array with no subCategory.
  if (['people', 'places'].includes(title)) {
    return (
      <div className="cat_watch-row series" style={{ display: showCategory ? 'inherit' : 'none' }}>
        <div className="cat_watch-body">
          <SubCategory key={title} data={Object.values(data)} noSiblingCategory={true} categoryName={title} />
        </div>
      </div>
    );
  }

  // Categories like BTS, 360-tours don't have sub-category.
  if (Array.isArray(data)) {
    return (
      !!data.length && (
        <div className="cat_watch-row series" style={{ display: showCategory ? 'inherit' : 'none' }}>
          <div className="cat_watch-body">
            <SubCategory data={data} noSiblingCategory={true} categoryName={title} />
          </div>
        </div>
      )
    );
  }

  return (
    <div className="cat_watch-row series" style={{ display: showCategory ? 'inherit' : 'none' }}>
      <div className="cat_watch-head cat-title">{title}</div>
      <div className="cat_watch-body">
        {Object.keys(data).map((subCategoryName, index) => (
          <SubCategory
            key={index}
            data={data[subCategoryName]}
            noSiblingCategory={false}
            categoryName={title}
            subCategoryName={subCategoryName}
            type="landscape"
          />
        ))}
      </div>
    </div>
  );
}
